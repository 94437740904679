<template>
  <v-chip
    :color="severityColor"
    dark
    x-small
    xoutlined
    class="text-uppercase px-2"
  >
    {{ severity }}
  </v-chip>
</template>
<script>
export default {
  props: {
    severity: {
      type: String,
      default: 'moderate',
    },
  },
  computed: {
    severityColor() {
      switch (this.severity) {
        case 'major':
        case 'severe':
          return 'red';
        case 'moderate':
          return 'orange darken-3';
        case 'minor':
        case 'mild':
          return 'yellow darken-3';
        case 'contraindicated':
          return 'blue-grey';
        default:
          return 'grey';
      }
    },
  },
}
</script>
